import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import ptbr from 'dayjs/locale/pt-br';
import { DiffParam } from './types';

dayjs.extend(customParseFormat);
dayjs.extend(isoWeek);
dayjs.locale(ptbr);

/**
 * PT-BR configured dayjs module instance.
 * */
export const $dayjs = dayjs;

export const parseStringToDate = (str: string): Date => dayjs(str).toDate();

export const formatDate = (date: Date, template = 'DD MMM YYYY') => dayjs(date).locale(ptbr).format(template);

export const formatStringToMonthYear = (date: string, template = 'MMMM/YYYY') =>
  dayjs(date, 'YYYY/MM/DD').locale(ptbr).format(template);

export const englishFormatDateFromString = (
  dateStr: string,
  inputFormat: string,
  outputFormat = 'DD/MM/YYYY, HH:mm',
): string => dayjs(dateStr, inputFormat).format(outputFormat);

export const formatDateFromString = (dateStr: string, inputFormat: string, outputFormat = 'DD/MM/YYYY, HH:mm') =>
  dayjs(dateStr, inputFormat).format(outputFormat);

export const formatToAmericanDateFromString = (
  dateStr: string,
  inputFormat: string,
  outputFormat = 'YYYY-MM-DD HH:mm:ss',
) => dayjs(dateStr, inputFormat).format(outputFormat);

export const getDiffFromDateStr = (firstDate: string, secondDate: string, diffParam: DiffParam): number => {
  const date1 = dayjs(firstDate);
  const date2 = dayjs(secondDate);

  return date1.diff(date2, diffParam);
};

export const nearExpirationDate = (deadline: string): boolean => {
  const providedDate = dayjs(deadline);
  const currentDate = dayjs();
  const nextDay = currentDate.add(1, 'day');

  const afterTodayDate = providedDate.isSame(nextDay, 'day') && providedDate.isAfter(currentDate);
  const todayDate = providedDate.isSame(currentDate, 'day');

  return afterTodayDate || todayDate;
};

export function transformToDate(input: number, outputFormat: string = 'YYYY-MM-DD HH:mm:ss') {
  const inputStr = input.toString();

  const day = inputStr.slice(0, 2);
  const month = inputStr.slice(2, 4);
  const year = inputStr.slice(4, 8);
  const hour = inputStr.slice(8, 10);
  const minute = inputStr.slice(10, 12);

  const formattedDate = `${year}/${month}/${day} ${hour}:${minute}:00`;

  const dateObj = new Date(formattedDate);

  return dayjs(dateObj).format(outputFormat);
}

export const checkIfDateisValid = (dateStr: string, inputFormat: string) => dayjs(dateStr, inputFormat, true).isValid();

export const checkIfDateIsBeforeCurrentDate = (dateStr: string, inputFormat: string) => {
  const currentDate = dayjs();
  const dateToVerify = dayjs(dateStr, inputFormat);

  return dateToVerify.isBefore(currentDate);
};

export const checkIfDateIsAfter = (dateStr: string, dateToVerify: string, inputFormat: string) =>
  dayjs(dateStr, inputFormat).isAfter(dateToVerify);

export const checkIfDateIsBefore = (dateStr: string, dateToVerify: string, inputFormat: string) =>
  dayjs(dateStr, inputFormat).isBefore(dateToVerify);

export const isPassedTime = (date: string) => {
  const now = dayjs();
  const formattedDate = dayjs(date, 'DD/MM/YYYY - HH:mm').format('YYYY-MM-DDTHH:mm:ss');
  const dateToCheck = dayjs(formattedDate);
  return now.isAfter(dateToCheck);
};

export const getWeekNumber = (dateStr: string): number => {
  return dayjs(dateStr, 'YYYY-MM-DD HH:mm:ss').isoWeek();
};

export function formatDateString(dateString: string) {
  if (!dateString || !/\d{4}-\d{2}-\d{2}/.test(dateString)) {
    throw new Error('Formato de data inválido');
  }
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
}
