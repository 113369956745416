import { gql } from '@apollo/client';

export const GET_AVERAGE_INDICATIVE_PRICES = gql`
  query GetAveragePrices($submarketType: Submarket!) {
    getAveragePrices(submarketType: $submarketType) {
      energyType
      indicativePricesCount
      periodData {
        averagePrice
        maxPrice
        minPrice
        periodNumber
        periodCreatedAt
        year
      }
    }
  }
`;
