import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { AveragePrice } from '@hooks/indicative-prices/queries/get_averages/types';
import { ChartComponent } from '@components/atoms/chart';
import TitleSection from '@components/molecules/indicative-prices-chart/title-section';
import { CHART_Z_INDEX, generateChartOptionsForYear, getGlobalMinMaxAndInterval, groupDataByYear } from './helper';
import ChartLegend from './chart-legend';
import ChartXAxis from './chart-x-axis';
import dayjs from 'dayjs';

export interface IndicativePricesChartProps {
  title: string;
  subtitle?: string;
  data: AveragePrice;
  colors?: string[];
}

const IndicativePricesChart: React.FC<IndicativePricesChartProps> = ({ title, subtitle, data, colors = [] }) => {
  const groupedData = groupDataByYear(data.periodData);
  const years = Object.keys(groupedData).map((year) => parseInt(year));
  const { globalMin, globalMax, yAxisInterval } = getGlobalMinMaxAndInterval(groupedData);
  const barsCount = groupedData[years[0]].length;

  const [activeChartIndex, setActiveChartIndex] = useState<number | null>(null);

  const handleTooltipShow = (chartIndex: number) => {
    setActiveChartIndex(chartIndex);
  };

  const handleTooltipHide = () => {
    setActiveChartIndex(null);
  };

  return (
    <div className="flex z-50 flex-col pt-5 pr-3 pb-6 pl-6 w-full rounded-3xl bg-brand-light-20">
      <TitleSection title={title} subtitle={subtitle} />
      <div className="flex overflow-x-auto relative -space-x-[6rem] -ml-[3rem]">
        {years.map((year, index) => (
          <div
            key={year}
            className={twMerge(
              'flex flex-col items-center',
              activeChartIndex === index ? 'z-50' : CHART_Z_INDEX[index],
            )}
            onMouseEnter={() => handleTooltipShow(index)}
            onMouseLeave={handleTooltipHide}
          >
            <ChartComponent.Root className="min-w-[18rem]">
              <ChartComponent.Content
                option={generateChartOptionsForYear(
                  groupedData[year],
                  index !== 0,
                  colors,
                  globalMin,
                  globalMax,
                  yAxisInterval,
                  index,
                  handleTooltipShow,
                  handleTooltipHide,
                )}
              />
            </ChartComponent.Root>
          </div>
        ))}
        <ChartXAxis years={years} />
        <ChartLegend
          colors={colors}
          dates={groupedData[years[0]].map((item) => dayjs(item.periodCreatedAt).format('DD/MM/YYYY'))}
          barsCount={barsCount}
        />
      </div>
    </div>
  );
};

export default IndicativePricesChart;
