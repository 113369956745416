import { PeriodData } from '@hooks/indicative-prices/queries/get_averages/types';
import dayjs from 'dayjs';

export const generateChartOptionsForYear = (
  yearData: PeriodData[],
  hideYAxis: boolean,
  colors: string[],
  globalMin: number,
  globalMax: number,
  yAxisInterval: number,
  chartIndex: number,
  handleTooltipShow: (index: number) => void,
  handleTooltipHide: () => void,
) => {
  const xAxisData = yearData.map((item) => dayjs(item.periodCreatedAt).format('DD/MM/YYYY'));

  const averageSeries = {
    name: 'Média',
    type: 'bar',
    data: yearData.map((item) => item.averagePrice),
    itemStyle: {
      color: (params: any) => colors[params.dataIndex % colors.length],
    },
    barGap: '8px',
  };

  const minSeries = {
    name: 'Ref. mínima',
    type: 'line',
    yAxisIndex: 1,
    data: yearData.map((item) => item.minPrice),
    itemStyle: {
      color: '#FFD97A',
    },
  };

  const maxSeries = {
    name: 'Ref. máxima',
    type: 'line',
    yAxisIndex: 1,
    data: yearData.map((item) => item.maxPrice),
    itemStyle: {
      color: '#AD7C00',
    },
  };

  return {
    tooltip: {
      trigger: 'axis',
      position: function (
        point: number[],
        _params: any,
        _dom: HTMLElement,
        _rect: any,
        size: { contentSize: number[]; viewSize: number[] },
      ) {
        const [x, y] = point;
        const [tooltipWidth, tooltipHeight] = size.contentSize;
        const [viewWidth, viewHeight] = size.viewSize;

        const position = { left: x, top: y };

        if (x + tooltipWidth <= viewWidth) {
          position.left = x + 10;
        } else if (x - tooltipWidth >= 0) {
          position.left = x - tooltipWidth - 10;
        }

        if (y - tooltipHeight >= 0) {
          position.top = y - tooltipHeight - 10;
        } else if (y + tooltipHeight <= viewHeight) {
          position.top = y + 10;
        }

        return [position.left, position.top];
      },
      formatter: function (params: any) {
        handleTooltipShow(chartIndex);
        let tooltipContent = `${params[0].axisValue}<br />`;
        params.forEach((param: any) => {
          const value = param.value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          tooltipContent += `${param.marker} ${param.seriesName}: ${value} <br />`;
        });
        return tooltipContent;
      },
      extraCssText: 'background: rgba(255, 255, 255, 0.9); border-radius: 10px; padding: 10px;',
      hideDelay: 0,
      showDelay: 0,
      enterable: true,
      transitionDuration: 0.2,
      borderWidth: 1,
      show: () => handleTooltipShow(chartIndex),
      hide: () => handleTooltipHide(),
    },
    legend: {
      show: false,
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisLabel: { show: false },
      axisLine: { show: false },
      axisTick: { show: false },
    },
    yAxis: [
      {
        type: 'value',
        min: globalMin - yAxisInterval,
        max: globalMax + yAxisInterval,
        interval: yAxisInterval,
        axisLabel: hideYAxis ? { show: false } : { formatter: 'R${value}' },
        axisLine: { show: false },
        axisTick: { show: false },
      },
      {
        type: 'value',
        min: globalMin - yAxisInterval,
        max: globalMax + yAxisInterval,
        interval: yAxisInterval,
        axisLabel: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
      },
    ],
    grid: {
      left: hideYAxis ? '45%' : '30%',
      right: '5%',
      bottom: '15%',
      containLabel: true,
    },
    series: [averageSeries, maxSeries, minSeries],
  };
};

export const getGlobalMinMaxAndInterval = (
  data: Record<number, PeriodData[]>,
): { globalMin: number; globalMax: number; yAxisInterval: number } => {
  let globalMin = Number.MAX_VALUE;
  let globalMax = Number.MIN_VALUE;

  Object.values(data).forEach((yearData) => {
    yearData.forEach((item) => {
      if (item.minPrice < globalMin) globalMin = item.minPrice;
      if (item.maxPrice > globalMax) globalMax = item.maxPrice;
    });
  });

  // Verifica se existe apenas um preço ou se os valores máximo e mínimo são iguais
  if (globalMin === globalMax) {
    globalMin = (globalMax / 20) * 10;
  }

  globalMin = Math.floor(globalMin / 10) * 10;
  globalMax = Math.ceil(globalMax / 10) * 10;
  // Calcula o intervalo de modo que o gráfico tenha 4 intervalos
  const range = globalMax - globalMin;
  const yAxisInterval = Math.ceil(range / 20) * 10; // Garante um múltiplo de 10

  return { globalMin, globalMax, yAxisInterval };
};

export const groupDataByYear = (periodData: PeriodData[]): Record<number, PeriodData[]> => {
  return periodData.reduce(
    (acc, item) => {
      if (!acc[item.year]) {
        acc[item.year] = [];
      }
      acc[item.year].push(item);
      return acc;
    },
    {} as Record<number, PeriodData[]>,
  );
};

export const CHART_Z_INDEX: Record<number, string> = {
  0: 'z-50',
  1: 'z-40',
  2: 'z-30',
  3: 'z-20',
  4: 'z-10',
};
